import { Box, Group, NumberInput, RangeSlider, Text } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { useState } from 'react';

import classes from '../project-menu-filters.module.css';

import {
  IProjectFilter,
  IProjectRunFilter
} from '@/core/types/project-filters.types';

export const RunsFilter = ({
  value,
  onChange
}: {
  value: [number, number];
  onChange: (filters: IProjectFilter[]) => void;
}) => {
  // Local State
  const [filterValue, setFilterValue] = useState(value || [0, 100]);

  // Handlers
  const updateRunsFilter = useDebouncedCallback((value: [number, number]) => {
    const fromFilter: IProjectRunFilter = {
      name: 'runs',
      value: value[0],
      operator: 'gte'
    };

    const toFilter: IProjectRunFilter = {
      name: 'runs',
      value: value[1],
      operator: 'lte'
    };

    onChange([fromFilter, toFilter]);
  }, 1_500);

  const handleChange = (value: [number, number]) => {
    setFilterValue(value);
    updateRunsFilter(value);
  };

  // Render
  return (
    <Box pb='md'>
      <Group justify='space-between' mt={12}>
        <Text c='gray.7' size='xs'>
          Falls within
        </Text>
        <Group gap='xs' justify='flex-end'>
          <NumberInput
            hideControls
            allowDecimal={false}
            classNames={classes}
            h={27}
            max={100}
            min={0}
            size='sm'
            value={filterValue?.[0] || 0}
            w={50}
            onChange={(value) => {
              handleChange([
                parseInt(value as string),
                filterValue?.[1] || 100
              ]);
            }}
          />
          <Text c='gray.7' size='xs'>
            to
          </Text>
          <NumberInput
            hideControls
            allowDecimal={false}
            classNames={classes}
            h={27}
            max={100}
            min={0}
            size='sm'
            value={filterValue?.[1] || 100}
            w={50}
            onChange={(value) => {
              handleChange([filterValue?.[0] || 0, parseInt(value as string)]);
            }}
          />
        </Group>
      </Group>

      <RangeSlider
        color='brand.5'
        marks={[
          { value: 0, label: '0' },
          { value: 50, label: '50' },
          { value: 100, label: '100' }
        ]}
        max={100}
        min={0}
        minRange={1}
        mt={10}
        size='md'
        step={1}
        thumbFromLabel='Minimum range'
        thumbToLabel='Maximum range'
        value={filterValue || [0, 100]}
        w='100%'
        onChange={handleChange}
      />
    </Box>
  );
};
