import {
  IconBarbell,
  IconHeartRateMonitor,
  IconPrompt,
  IconPuzzle
} from '@tabler/icons-react';

import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';

const useProjectType = (projectType: ProjectType) => {
  switch (projectType) {
    case PROJECT_TYPE.PROMPT_EVALUATION:
      return {
        label: 'Evaluate',
        shortLabel: 'Evaluation',
        Icon: IconPrompt
      };
    case PROJECT_TYPE.OBSERVE:
      return {
        label: 'Observe',
        shortLabel: 'Observation',
        Icon: IconHeartRateMonitor
      };
    case PROJECT_TYPE.TRAINING_INFERENCE:
      return {
        label: 'Training & Finetuning',
        shortLabel: 'Finetune',
        Icon: IconBarbell
      };
    default:
      return {
        label: 'Unknown',
        shortLabel: 'Unknown',
        Icon: IconPuzzle
      };
  }
};

export default useProjectType;
