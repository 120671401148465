import { ActionIcon, Box, Stack, Text, Tooltip } from '@mantine/core';
import {
  IconBarbell,
  IconHeartRateMonitor,
  IconPlus,
  IconPrompt
} from '@tabler/icons-react';
import Link from 'next/link';

import MenuAvatar from '@/core/components/molecules/menu-avatar/menu-avatar';
import NavActionIcon from '@/core/components/molecules/nav-action-icon/nav-action-icon';
import { Logo } from '@/core/constants/galileo-logos.constants';
import { Paths } from '@/core/constants/routes.constants';
import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { useGenericPermissions } from '@/core/hooks/use-permissions/use-generic-permissions';
import useProjectType from '@/core/hooks/use-project-type/use-project-type';
import { DevToolsDrawer } from '@/core/page-components/dev-tools/dev-tools-drawer/dev-tools-drawer';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';

import { LinkActionIcon } from '../../atoms/link-action-icon/link-action-icon';
import SkeletonLoader from '../../atoms/skeleton-loader/skeleton-loader';

/**
 * NavBar
 * @returns {React.Component} NavBar
 */
const NavBar = () => {
  // Feature Flags
  let { prompt, observe } = useFeatureFlags();

  // Global Store
  const isProjectMenuOpen = useGlobalStore((s) => s.isProjectMenuOpen);
  const { setIsProjectMenuOpen } = useGlobalStore((s) => s.actions);

  // Query Hooks
  const { data: user, isLoading: isCurrentUserLoading } = useCurrentUser();

  // Permissions
  const { getGenericPermissions } = useGenericPermissions();
  const { generic_create } = getGenericPermissions(
    user?.generic_permissions || [],
    'project'
  );

  // Handlers
  const handleToggleProjectSearch = (type: ProjectType) => {
    setIsProjectMenuOpen(isProjectMenuOpen === type ? false : type);
  };

  const onCreateNewProject = () => {
    if (isProjectMenuOpen) {
      setIsProjectMenuOpen(false);
    }
  };

  const trainingProjectType = useProjectType(PROJECT_TYPE.TRAINING_INFERENCE);
  const evaluateProjectType = useProjectType(PROJECT_TYPE.PROMPT_EVALUATION);
  const observeProjectType = useProjectType(PROJECT_TYPE.OBSERVE);

  return (
    <Box
      className='flex-column'
      data-testid='nav-bar'
      h='100%'
      py='sm'
      style={{
        backgroundColor: 'var(--mantine-color-contrast-8)',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRight: `1px solid var(--mantine-color-gray-2)`,
        zIndex: 201
      }}
    >
      <Stack>
        <Tooltip withArrow label='Go to latest runs' position='right-end'>
          <Link passHref href={Paths.ROOT}>
            <ActionIcon
              aria-label='Go to latest runs'
              size='xl'
              variant='transparent'
            >
              <Logo />
            </ActionIcon>
          </Link>
        </Tooltip>
        {isCurrentUserLoading && (
          <SkeletonLoader height={40} length={1} variant='circle' width={40} />
        )}
        {!isCurrentUserLoading && (
          <LinkActionIcon
            actionIconProps={{
              variant: 'transparent',
              size: 'xl',
              color: 'black',
              style: {
                border: '1px solid var(--mantine-color-gray-2)'
              }
            }}
            ariaLabel='Navigate to create new project page'
            href={Paths.CREATE_NEW_PROJECT}
            overlayProps={{
              color: 'var(--mantine-color-contrast-8)'
            }}
            permissions={generic_create}
            onClick={onCreateNewProject}
          >
            <IconPlus />
          </LinkActionIcon>
        )}

        <NavActionIcon
          data-testid='project-search'
          hoverColor='#E9E8ED'
          icon={<IconBarbell color='black' />}
          label={
            <Box className='justify-center flex-column'>
              <Text>{`${trainingProjectType.label} projects`}</Text>
            </Box>
          }
          onClick={() =>
            handleToggleProjectSearch(PROJECT_TYPE.TRAINING_INFERENCE)
          }
        />
        {prompt.isEnabled && (
          <NavActionIcon
            data-testid='prompt-search'
            hoverColor='#E9E8ED'
            icon={<IconPrompt color='black' />}
            label={
              <Box className='justify-center flex-column'>
                <Text>{`${evaluateProjectType.label} projects`}</Text>
              </Box>
            }
            onClick={() =>
              handleToggleProjectSearch(PROJECT_TYPE.PROMPT_EVALUATION)
            }
          />
        )}
        {observe.isEnabled && (
          <NavActionIcon
            data-testid='observe-search'
            hoverColor='#E9E8ED'
            icon={<IconHeartRateMonitor color='black' />}
            label={
              <Box className='justify-center flex-column'>
                <Text>{`${observeProjectType.label} projects`}</Text>
              </Box>
            }
            onClick={() => handleToggleProjectSearch(PROJECT_TYPE.OBSERVE)}
          />
        )}
      </Stack>
      <Stack>
        <DevToolsDrawer />
        <MenuAvatar position='right-end' />
      </Stack>
    </Box>
  );
};

export default NavBar;
