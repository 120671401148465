import { Box } from '@mantine/core';
import check from 'check-types';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { useProjectBookmark } from '@/core/hooks/query-hooks/use-project-bookmark/use-project-bookmark';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import {
  Project,
  PROJECT_TYPE,
  ProjectType
} from '@/core/types/projects.types';

import InsightsMenuRow from './insights-menu-row';
import LLMMenuRow from './llm-menu-row';

interface ProjectMenuRowProps {
  expandProject: (id: string) => void;
  data: Project[];
  expandedRows: string[];
  height: number | string;
  scrollToActiveRow: boolean;
  onFetchNextPage?: () => void;
}

// Exported for testing only
export const getStartIndex = (
  data: Project[],
  projectId: string | undefined,
  scrollToActiveRow: boolean
) => {
  if (!scrollToActiveRow || !projectId) {
    return 0;
  }
  const index = data?.findIndex((row) => row?.id === projectId);
  const parsedIndex = check.positive(index) ? index : 0;

  return parsedIndex;
};

const ProjectMenuRows = ({
  expandProject,
  data,
  expandedRows,
  height,
  scrollToActiveRow,
  onFetchNextPage
}: ProjectMenuRowProps) => {
  const { projectId } = usePathParameters();
  const startIndex = getStartIndex(data, projectId, scrollToActiveRow);

  // Local State
  const [hovered, setHovered] = useState<string | false>(false);
  const ref = useRef<VirtuosoHandle>(null);

  // Hooks
  const bookmarkProject = useProjectBookmark();

  // Handlers
  const handleBookmarkProject = (
    e: MouseEvent<HTMLButtonElement>,
    id: string,
    type: ProjectType,
    isBookmarked: boolean
  ) => {
    e.stopPropagation();
    bookmarkProject.mutate({ projectId: id, projectType: type, isBookmarked });
  };

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.scrollToIndex({
          index: startIndex,
          align: 'start'
        });
      }, 100);
    }
  }, [startIndex]);

  return (
    <Virtuoso
      data={data}
      endReached={onFetchNextPage}
      itemContent={(_, project) => {
        const { type } = project;

        const isPrompt = type === PROJECT_TYPE.PROMPT_EVALUATION;
        const isObserve = type === PROJECT_TYPE.OBSERVE;
        const isLLMProject = isObserve || isPrompt;

        const handler = (e: MouseEvent<HTMLButtonElement>) => {
          if (!project?.type) {
            return;
          }

          handleBookmarkProject(
            e,
            project.id,
            project?.type,
            Boolean(project.bookmark)
          );
        };

        return (
          <Box
            onMouseEnter={() => setHovered(project.id)}
            onMouseLeave={() => setHovered(false)}
          >
            {isLLMProject ? (
              <LLMMenuRow
                handleBookmarkProject={handler}
                hovered={hovered === project.id}
                isSelected={projectId === project.id}
                key={project.id}
                project={project}
              />
            ) : (
              <InsightsMenuRow
                expandedRows={expandedRows}
                expandRow={() => expandProject(project.id)}
                handleBookmarkProject={handler}
                hovered={hovered === project.id}
                key={project.id}
                project={project}
              />
            )}
          </Box>
        );
      }}
      ref={ref}
      style={{ height }}
    />
  );
};

export default ProjectMenuRows;
