import { Box } from '@mantine/core';

import NoProjectsByType from '@/core/components/atoms/no-projects-by-type/no-projects-by-type';
import { ProjectType } from '@/core/types/projects.types';
import { Permission } from '@/core/types/user-permissions.types';

const ProjectMenuEmptyState = ({
  activeMenu,
  permissions
}: {
  activeMenu: ProjectType;
  permissions?: Permission;
}) => {
  return (
    <Box
      data-testid='project-menu-empty-state'
      mt='10vh'
      style={{ height: '100vh', visibility: 'visible' }}
    >
      <NoProjectsByType large permissions={permissions} type={activeMenu} />
    </Box>
  );
};

export default ProjectMenuEmptyState;
