import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import api from '@/core/api';
import {
  EVALUATE_COLUMNAR_RUNS_PATH,
  EVALUATE_RUNS_PATH,
  PROJECT_RUN_PATH
} from '@/core/constants/api.constants';
import { Paths } from '@/core/constants/routes.constants';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/strings.constants';
import { showNotification } from '@/core/utils/show-notification/show-notification';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

import QueryKeyFactory from '../query-key-factory/query-key-factory';
import { GET_LATEST_RUN } from '../use-latest-runs/use-latest-runs';
import { MY_RUNS_KEY } from '../use-my-runs/use-my-runs';

export const useDeleteRun = (inProgress?: boolean) => {
  const router = useRouter();
  const activeRunId = useParametersStore((state) => state.runId);

  const queryClient = useQueryClient();

  let isDeletingCurrentRun = false;

  const handleDeleteRun = async ({
    runId,
    projectId
  }: {
    runId: string;
    projectId: string;
  }) => {
    isDeletingCurrentRun = activeRunId === runId;

    const path = '/projects/{project_id}/runs/{run_id}';

    return api
      .DELETE(path, {
        params: {
          path: {
            project_id: projectId,
            run_id: runId
          }
        }
      })
      .then((res) => {
        showNotification({
          type: inProgress ? 'warning' : undefined,
          title: inProgress ? 'Run has been canceled.' : 'Success',
          message: inProgress
            ? 'All information has been removed and a cost has been incurred.'
            : 'Run deleted successfully'
        });

        // Remove deleted run from projects path on only the affected project
        queryClient.setQueriesData<{ id: string; runs: { id: string }[] }[]>(
          [QueryKeyFactory().projects.path],
          (cache) =>
            cache?.map((p) =>
              p.id === projectId
                ? { ...p, runs: p.runs.filter((r) => r.id !== runId) }
                : p
            )
        );

        queryClient.invalidateQueries([MY_RUNS_KEY]);
        queryClient.invalidateQueries([EVALUATE_RUNS_PATH]);
        queryClient.invalidateQueries([PROJECT_RUN_PATH]);
        queryClient.invalidateQueries([GET_LATEST_RUN]);
        queryClient.invalidateQueries([EVALUATE_COLUMNAR_RUNS_PATH]);
        queryClient.invalidateQueries(
          QueryKeyFactory().project.queryKey({ projectId })
        );

        if (isDeletingCurrentRun) {
          router.push(Paths.ROOT);
        }

        return res.data;
      })
      .catch(async (error) => {
        error = await error.json();
        const errStr = error.detail.map((err: any) => err.msg).join('\n');

        showNotification({
          title: 'Error deleting run',
          message: errStr || GENERIC_ERROR_MESSAGE,
          type: 'error'
        });
      });
  };
  return useMutation(handleDeleteRun);
};
