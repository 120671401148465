import { AppShell } from '@mantine/core';

import NavBar from '@/core/components/organisms/nav-bar/nav-bar';
import ProjectMenu from '@/core/components/organisms/project-menu/project-menu';

export default function ProductLayout({ children }: any) {
  return (
    <AppShell
      layout='alt'
      navbar={{ width: 65, breakpoint: 0 }}
      withBorder={false}
    >
      <AppShell.Navbar>
        <NavBar />
        <ProjectMenu />
      </AppShell.Navbar>
      <AppShell.Main h='100vh'>{children}</AppShell.Main>
    </AppShell>
  );
}
