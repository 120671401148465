import { Button, ButtonProps, Tooltip } from '@mantine/core';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { PropsWithChildren } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { usePrefetch } from '@/core/hooks/use-prefetch/use-prefetch';
import { Permission } from '@/core/types/user-permissions.types';

interface LinkButtonProps extends LinkProps {
  buttonProps?: ButtonProps;
  permissions?: Permission;
  onClick?: () => void;
}
export function LinkButton({
  children,
  permissions,
  buttonProps,
  onClick,
  ...props
}: PropsWithChildren<LinkButtonProps>) {
  // Router
  const router = useRouter();

  // Feature Flags
  const { RBAC_M2 } = useFeatureFlags();

  // Computed
  const isDisabled = RBAC_M2.isEnabled && permissions && !permissions.allowed;
  const isTooltipDisabled = !isDisabled || !permissions?.message;

  // Prefetch Hook
  usePrefetch({
    isDisabled: Boolean(isDisabled),
    href: props.href
  });

  // Handlers
  const navigate = async () => {
    onClick?.();

    await router.push(props.href, props?.as, {
      shallow: props?.shallow,
      scroll: props?.scroll
    });
  };

  // Render
  return (
    <Tooltip
      disabled={isTooltipDisabled}
      label={permissions?.message}
      zIndex={Z_INDEX.TOOLTIPS}
    >
      <Button
        disabled={isDisabled}
        role='link'
        {...buttonProps}
        onClick={navigate}
      >
        {children}
      </Button>
    </Tooltip>
  );
}
