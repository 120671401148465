import { Url } from 'next/dist/shared/lib/router/router';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

// Prefetching is done out of the box in <Link/> components. This hook provides prefetching functionality for other components
export function usePrefetch({
  isDisabled,
  href
}: {
  isDisabled: boolean;
  href: Url;
}) {
  // Router
  const router = useRouter();

  // Effect
  useEffect(() => {
    if (isDisabled) {
      return;
    }

    if (typeof href === 'string') {
      router.prefetch(href);
    } else if (href?.pathname) {
      router.prefetch(href.pathname);
    }
  }, [router]);
}
