import { Button, Group, Menu, Popover, Select, Text } from '@mantine/core';
import { IconArrowsSort, IconCheck, IconFilter } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from 'react';

import classes from './project-menu-filters.module.css';

import SkeletonLoader from '@/core/components/atoms/skeleton-loader/skeleton-loader';
import {
  IProjectFilter,
  IProjectSort
} from '@/core/types/project-filters.types';

import { CreatorFilter } from './filters/creator-filter';
import { DateFilter } from './filters/date-filter';
import { RunsFilter } from './filters/runs-filter';

export interface ProjectMenuFiltersProps {
  isLoading?: boolean;
  isEvaluateMenu?: boolean;
  sortProps: IProjectSort | undefined;
  setSortProps: (sort: IProjectSort) => void;
  filterProps?: IProjectFilter[];
  setFilterProps: (props: IProjectFilter[]) => void;
}

export type IProjectSortOption = {
  label: string;
  value: string;
  filter: IProjectSort;
};

const sortByOptions: IProjectSortOption[] = [
  {
    value: 'name-asc',
    label: 'Project name: A-Z',
    filter: { name: 'name', ascending: true }
  },
  {
    value: 'name-desc',
    label: 'Project name: Z-A',
    filter: { name: 'name', ascending: false }
  },
  {
    value: 'runs-desc',
    label: 'Number of runs: most to least',
    filter: { name: 'runs', ascending: false }
  },
  {
    value: 'runs-asc',
    label: 'Number of runs: least to most',
    filter: { name: 'runs', ascending: true }
  },
  {
    value: 'created_at-desc',
    label: 'Created date: newest to oldest',
    filter: { name: 'created_at', ascending: false }
  },
  {
    value: 'created_at-asc',
    label: 'Created date: oldest to newest',
    filter: { name: 'created_at', ascending: true }
  },
  {
    value: 'updated_at-desc',
    label: 'Last modified date: newest to oldest',
    filter: { name: 'updated_at', ascending: false }
  },
  {
    value: 'updated_at-asc',
    label: 'Last modified date: oldest to newest',
    filter: { name: 'updated_at', ascending: true }
  }
];

const projectMenuFilters = [
  { value: 'none', label: 'None' },
  { value: 'runs', label: 'Number of runs' },
  { value: 'creator', label: 'Creator' },
  { value: 'created_at', label: 'Created date' },
  { value: 'updated_at', label: 'Last modified date' }
];

const ProjectMenuFilters = ({
  isLoading,
  sortProps,
  setSortProps,
  filterProps,
  setFilterProps
}: ProjectMenuFiltersProps) => {
  // Local State
  const [filterType, setFilterType] = useState<
    IProjectFilter['name'] | 'none' | undefined
  >(filterProps?.[0]?.name);

  // Computed
  const selectedFilter = projectMenuFilters.find((f) => f.value === filterType);
  const selectedSort = sortByOptions.find(
    (s) =>
      s.filter.name === sortProps?.name &&
      s.filter.ascending === sortProps?.ascending
  );

  // Handlers
  const handleFilterChange = useCallback((value: string) => {
    setFilterProps([]);
    setFilterType(value as IProjectFilter['name']);
  }, []);

  const handleSubmitFilters = (filters: IProjectFilter[]) => {
    setFilterProps(filters);
  };

  const handleSortChange = (sort: IProjectSort) => {
    if (sort == null) {
      return;
    }

    setSortProps(sort);
  };

  // Effects
  useEffect(() => {
    setFilterProps([]);
  }, [filterType]);

  // Render
  if (isLoading) {
    return (
      <SkeletonLoader
        flexProps={{ align: 'flex-start' }}
        height={25}
        length={1}
        mr='xs'
        orientation='vertical'
        width={250}
      />
    );
  }

  return (
    <Group>
      <Popover withArrow position='bottom' shadow='md' width={330}>
        <Popover.Target>
          <Button
            color='#9B98AE'
            h={36}
            leftSection={<IconFilter color='#9B98AE' size={14} />}
            p={0}
            styles={{
              section: {
                marginRight: 4
              }
            }}
            variant='transparent'
          >
            Filter for{' '}
            {selectedFilter?.label ? (
              <Text c='#706C89' fw={600} ml={4}>
                {selectedFilter?.label}
              </Text>
            ) : null}
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <Select
            classNames={{ input: classes['select-input'] }}
            comboboxProps={{ withinPortal: false }}
            data={projectMenuFilters}
            placeholder='Select a filter'
            value={selectedFilter?.value}
            onChange={(val) =>
              handleFilterChange(val as IProjectFilter['name'])
            }
          />
          {selectedFilter?.value === 'runs' && (
            <RunsFilter
              value={
                (filterProps?.length &&
                  (filterProps?.map((f) => f.value) as [number, number])) || [
                  0, 100
                ]
              }
              onChange={handleSubmitFilters}
            />
          )}
          {selectedFilter?.value === 'creator' && (
            <CreatorFilter
              value={filterProps?.[0]?.value as string}
              onChange={handleSubmitFilters}
            />
          )}
          {/* {selectedFilter?.value === 'run_type' && (
            <Select
              classNames={{ input: classes['select-input'] }}
              comboboxProps={{ zIndex: 10000, withinPortal: false }}
              data={[
                { label: 'Chains', value: TASK_TYPE.PROMPT_CHAINS },
                { label: 'Prompt', value: TASK_TYPE.PE }
              ]}
              mt={6}
              placeholder='Select run type'
              value={filterProps.filterValue as string}
              onChange={(value) =>
                setFilterProps({
                  filterBy: 'run_type',
                  filterValue: value as string
                })
              }
            />
          )} */}
          {(selectedFilter?.value === 'created_at' ||
            selectedFilter?.value === 'updated_at') && (
            <DateFilter
              type={selectedFilter.value}
              value={
                (filterProps != null &&
                  filterProps.length > 0 &&
                  (filterProps.map((f) => f.value) as [string, string])) || [
                  null,
                  null
                ]
              }
              onChange={handleSubmitFilters}
            />
          )}
        </Popover.Dropdown>
      </Popover>
      <Menu withArrow position='bottom' shadow='md' width={300}>
        <Menu.Target>
          <Button
            color='#9B98AE'
            h={30}
            leftSection={<IconArrowsSort color='#9B98AE' size={14} />}
            styles={{
              section: {
                marginRight: 4
              }
            }}
            variant='transparent'
          >
            Sort by
            {selectedSort?.label ? (
              <Text c='#706C89' fw={600} ml={4}>
                {selectedSort?.label}
              </Text>
            ) : null}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {sortByOptions.map((option) => {
            const isSelected = option.value === sortProps?.name;
            return (
              <Menu.Item
                key={option.value}
                leftSection={isSelected ? <IconCheck size={12} /> : undefined}
                onClick={() => handleSortChange(option.filter)}
              >
                {option.label}
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

export default ProjectMenuFilters;
