import {
  ActionIcon,
  Avatar,
  Box,
  Grid,
  Group,
  Text,
  Tooltip
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconBarbell, IconPlus, IconTrash } from '@tabler/icons-react';
import { Url } from 'next/dist/shared/lib/router/router';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEvent, useEffect, useState } from 'react';

import classes from './project-menu-button.module.css';

import { Paths } from '@/core/constants/routes.constants';
import {
  TASK_TYPE,
  TaskType
} from '@/core/constants/tasks-and-frameworks.constants';
import { useDeleteRun } from '@/core/hooks/query-hooks/use-delete-run/use-delete-run';
import { useModelsByProject } from '@/core/hooks/query-hooks/use-models-by-project/use-models-by-project';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { Run } from '@/core/types/projects.types';
import { Permission } from '@/core/types/user-permissions.types';
import { parseDate } from '@/core/utils/parse-date/parse-date';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import { toHumanReadableTaskType } from '@/core/utils/to-human-readable-task-type/to-human-readable-task-type';

interface ProjectMenuButtonProps {
  isSelected?: boolean;
  createRunPermission?: Permission;
  deleteRunPermission?: Permission;
  run: Run;
  url: Url;
}

const ProjectMenuButton = ({
  isSelected = false,
  createRunPermission,
  deleteRunPermission,
  run,
  url
}: ProjectMenuButtonProps) => {
  // Local state
  const [allowPush, setAllowPush] = useState(false);

  // Router
  const router = useRouter();

  // Props
  const { task_type } = run || {};

  // Hooks
  const { csvTraining } = useFeatureFlags();
  const { ref, hovered } = useHover();
  const { openConfirmModal } = useModals();
  const deleteRun = useDeleteRun();
  const { setIsProjectMenuOpen } = useGlobalStore((state) => state.actions);

  // Computed
  const taskType = task_type?.toString() as TaskType;
  const humanReadableTaskType = toHumanReadableTaskType(taskType);
  const isMCTC = taskType === TASK_TYPE.MCTC;
  const isNer = taskType === TASK_TYPE.NER;
  const canAddInferenceRun = isMCTC || isNer;

  const { data: modelData } = useModelsByProject(
    run.project_id || '',
    canAddInferenceRun
  );
  const hasModels = (modelData || []).length > 0;
  const allowAddingInferenceRun =
    csvTraining.isEnabled &&
    canAddInferenceRun &&
    hasModels &&
    createRunPermission?.allowed;

  useEffect(() => {
    const handleRouteChange = () => {
      if (allowPush) {
        setAllowPush(false);
        router.push(url);
      }
    };

    router.events.on('routeChangeError', handleRouteChange);

    return () => {
      router.events.off('routeChangeError', handleRouteChange);
    };
  }, [router, allowPush]);

  // Handlers
  const handleDeleteRun = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    openConfirmModal({
      title: 'Delete Run',
      children: (
        <Text size='sm'>Are you sure you want to delete run {run.name}?</Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        deleteRun.mutate({
          runId: run.id,
          projectId: run.project_id!
        });
      },
      closeOnCancel: true
    });
  };

  const addInferenceRun = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsProjectMenuOpen(false);
    router.push({
      pathname: Paths.NEW_PROJECT,
      query: { isInference: true, projectId: run.project_id, runName: run.name }
    });
  };

  const handleRunChange = () => {
    setIsProjectMenuOpen(false);
    // Resetting the store already causes router changes so we need to wait until the next render cycle to push the new route
    setAllowPush(true);
  };

  return (
    <Box
      className={classes.projectMenuButtonContainer}
      data-testid='project-menu-button-container'
      mb={4}
      ml={20}
      p='xs'
      ref={ref}
      style={{
        border: `1px solid ${
          isSelected
            ? 'var(--mantine-color-brand-3)'
            : 'var(--mantine-color-gray-2)'
        }`
      }}
    >
      <Link
        className='unstyled-anchor flex'
        href={url || {}}
        onClick={handleRunChange}
      >
        <Box
          className='w-100 project-menu-button'
          component='div'
          data-testid='project-menu-button'
          id={run?.id}
          pos='relative'
        >
          <Grid className='h-100' gutter={2}>
            <Grid.Col span={1}>
              <IconBarbell color='#8674FB' size={20} />
            </Grid.Col>
            <Tooltip
              withArrow
              withinPortal
              disabled={(run?.name?.length || 0) <= 45}
              label={run?.name}
            >
              <Grid.Col span={9}>
                <Text className='truncate' size='sm' style={{ maxWidth: 550 }}>
                  {run?.name}
                </Text>
                <Box className='align-center' mt={4}>
                  <Text c='dimmed' ml={2} size='xs'>
                    {parseDate(run?.created_at || '')} &#8226;
                  </Text>
                  <Text c='dimmed' ml={2} mr={2} size='xs'>
                    {toHumanReadableNumber(run?.num_samples, { fallback: 0 })}{' '}
                    samples &#8226;
                  </Text>
                  <Text c='dimmed' mr={2} size='xs'>
                    {humanReadableTaskType}
                  </Text>
                </Box>
              </Grid.Col>
            </Tooltip>
            <Box bottom={0} pos='absolute' right={2}>
              <Group className='align-center' gap={8} wrap='nowrap'>
                {allowAddingInferenceRun && (
                  <Tooltip
                    withArrow
                    withinPortal
                    label='Add inference run'
                    position='left'
                  >
                    <IconPlus
                      aria-label='Add inference run'
                      color='#1C1B22'
                      data-testid='add-inference-run-button'
                      size={16}
                      style={{
                        visibility: hovered ? 'visible' : 'hidden'
                      }}
                      onClick={addInferenceRun}
                    />
                  </Tooltip>
                )}
                {deleteRunPermission?.allowed && (
                  <Tooltip
                    withArrow
                    withinPortal
                    label='Delete run'
                    position='bottom'
                  >
                    <ActionIcon
                      aria-label='Delete run'
                      color='gray'
                      data-testid='delete-run-button'
                      size='sm'
                      style={{
                        visibility: hovered ? 'visible' : 'hidden'
                      }}
                      variant='transparent'
                      onClick={(e) => handleDeleteRun(e)}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Tooltip>
                )}
                {run?.creator?.first_name && (
                  <Avatar
                    data-testid='creator-avatar'
                    radius='xl'
                    size={24}
                    styles={{
                      placeholder: {
                        backgroundColor: '#9791F3',
                        color: 'white',
                        fontSize: 12
                      }
                    }}
                  >
                    {run?.creator?.first_name?.[0]}
                  </Avatar>
                )}
              </Group>
            </Box>
          </Grid>
        </Box>
      </Link>
    </Box>
  );
};

export default ProjectMenuButton;
