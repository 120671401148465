import {
  ActionIcon,
  Box,
  Group,
  Text,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import {
  IconBookmark,
  IconBookmarkFilled,
  IconTrash
} from '@tabler/icons-react';
import Link from 'next/link';
import { MouseEvent } from 'react';

import classes from './project-menu-rows.module.css';

import UserName from '@/core/components/atoms/user-name/user-name';
import { Paths } from '@/core/constants/routes.constants';
import { TASK_TYPE } from '@/core/constants/tasks-and-frameworks.constants';
import { useProjectPermissions } from '@/core/hooks/use-permissions/use-project-permissions';
import { useProjectDeleteConfirmModal } from '@/core/modals/project-delete-confirm-modal/project-delete-confirm-modal';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { Project, PROJECT_TYPE } from '@/core/types/projects.types';
import { ProjectPermissionBlock } from '@/core/types/user-permissions.types';
import { parseDate } from '@/core/utils/parse-date/parse-date';
import { usePromptStore } from '@/evaluate/stores/prompt-store/prompt.store';

const LLMMenuRow = ({
  project,
  handleBookmarkProject,
  hovered,
  isSelected
}: {
  project: Project;
  handleBookmarkProject: (e: MouseEvent<HTMLButtonElement>) => void;
  hovered: boolean;
  isSelected: boolean;
}) => {
  // Prompt store
  const setJobId = usePromptStore((s) => s.actions.setJobId);
  const setIsProjectMenuOpen = useGlobalStore(
    (s) => s.actions.setIsProjectMenuOpen
  );

  // Hooks
  const showProjectDeleteConfirmModal = useProjectDeleteConfirmModal();

  // Permissions
  const { getProjectPermissions } = useProjectPermissions();
  const { delete: deleteProject } = getProjectPermissions(
    (project?.permissions as ProjectPermissionBlock[]) || []
  );

  // Computed
  const { name, id: projectId, runs, type, bookmark, updated_at } = project;

  const showProjectDelete = deleteProject.allowed;

  const isPrompt = type === PROJECT_TYPE.PROMPT_EVALUATION;
  const isPromptChains =
    runs[0]?.task_type === parseInt(TASK_TYPE.PROMPT_CHAINS);
  const isObserve = type === PROJECT_TYPE.OBSERVE;

  const runsAmount = runs?.length;
  let pathname;

  if (isPrompt) {
    pathname = isPromptChains ? Paths.PROMPT_CHAINS_RUNS : Paths.PROMPT_RUNS;
  }

  if (isObserve) {
    pathname = Paths.OBSERVE;
  }

  const url = {
    pathname,
    query: {
      projectId
    }
  };

  // Handlers
  const resetStoredValues = () => {
    setJobId(undefined);
    setIsProjectMenuOpen(false);
  };

  // Render
  return (
    <Box
      data-testid={`project-menu-project-${name}`}
      id={projectId}
      key={projectId}
      pos='relative'
      px={4}
    >
      <Box className='flex' pos='absolute' right={12} top={8}>
        {hovered && (
          <>
            {!bookmark && (
              <Tooltip
                withArrow
                withinPortal
                label='Bookmark'
                position='bottom'
              >
                <ActionIcon
                  aria-label='Bookmark project'
                  color='gray.7'
                  data-testid='bookmark-icon'
                  mr={4}
                  size={24}
                  variant='transparent'
                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                    handleBookmarkProject(e)
                  }
                >
                  <IconBookmark className={classes.rowIcon} size={16} />
                </ActionIcon>
              </Tooltip>
            )}
            {showProjectDelete && (
              <Tooltip
                withArrow
                withinPortal
                label='Delete project'
                position='bottom'
              >
                <ActionIcon
                  aria-label='Delete project'
                  color='gray.7'
                  data-testid='delete-icon'
                  mr={4}
                  size={24}
                  variant='transparent'
                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                    showProjectDeleteConfirmModal(e, name || '', projectId)
                  }
                >
                  <IconTrash className={classes.rowIcon} size={16} />
                </ActionIcon>
              </Tooltip>
            )}
          </>
        )}
        {bookmark && (
          <Tooltip
            withArrow
            withinPortal
            label='Remove bookmark'
            position='bottom'
          >
            <ActionIcon
              aria-label='Remove bookmark'
              color='gray'
              mr={4}
              size={24}
              variant='transparent'
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                handleBookmarkProject(e)
              }
            >
              <IconBookmarkFilled color='#8674FB' size={16} />
            </ActionIcon>
          </Tooltip>
        )}
      </Box>
      <Link className='unstyled-anchor' href={url} onClick={resetStoredValues}>
        <UnstyledButton
          aria-label={`Open ${name}`}
          className={
            isSelected ? classes.promptButtonSelected : classes.promptButton
          }
          component='div'
          data-testid='project-menu-project'
          px='xs'
          py={6}
          role='button'
          w='100%'
        >
          <Box className='flex justify-space-between'>
            <Box className='align-center'>
              <UserName
                fontSize={11}
                id={project.created_by}
                showFullName={false}
                // @ts-expect-error
                size={20}
                tooltipText='Owned by'
              />
              <Text truncate c='#383645' fw={600} maw={300} ml='xs' size='sm'>
                {name}
              </Text>
            </Box>
          </Box>
          <Group justify='space-between' mt={4}>
            <Text c='#9B98AE' fw={400} ml={24} size='sm'>
              Last updated on {parseDate(updated_at || '')}
            </Text>
            {isPrompt && (
              <Text c='#9B98AE' fs='italic' fw={400} ml={24} size='sm'>
                {runsAmount} Run{runsAmount === 1 ? '' : 's'}
              </Text>
            )}
          </Group>
        </UnstyledButton>
      </Link>
    </Box>
  );
};

export default LLMMenuRow;
