import {
  ActionIcon,
  Box,
  Card,
  ComboboxData,
  Container,
  Drawer,
  Group,
  ScrollAreaAutosize,
  Select,
  Switch,
  Tabs,
  Text
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconAB2, IconFlask2Filled, IconLockAccess } from '@tabler/icons-react';

import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { FeatureFlag, FlagTags } from '@/core/types/feature-flags.types';

export const DevToolsDrawer = () => {
  // Local State
  const [open, handlers] = useDisclosure(false);

  // Hooks
  const allFlags = useFeatureFlags();
  const currentUser = useCurrentUser();

  // Computed
  let ABFlags: FeatureFlag[] = [];
  let externalFeatureFlags: FeatureFlag[] = [];
  let devTools: FeatureFlag[] = [];
  let permissionFlags: FeatureFlag[] = [];

  for (let flagKey in allFlags) {
    const flag = allFlags[flagKey as keyof typeof allFlags];

    if (flag.tag.includes(FlagTags.Feature)) {
      ABFlags.push(flag);
    }

    if (flag.tag.includes(FlagTags.External)) {
      externalFeatureFlags.push(flag);
    }

    if (flag.tag.includes(FlagTags.Tool)) {
      devTools.push(flag);
    }

    if (flag.tag.includes(FlagTags.Permission)) {
      permissionFlags.push(flag);
    }
  }

  // Separate out this variable so we don't lose access to flags if we toggle `isGalileoUser`
  const _isGalileoUser = Boolean(
    currentUser?.data?.email?.match(/@rungalileo.io/)
  );

  if (!_isGalileoUser) {
    return null;
  }

  const mapCard = (flag: FeatureFlag) => {
    // Select Flag
    if ('options' in flag) {
      return (
        <FeatureFlagCard
          description={flag.description}
          key={flag.title}
          label={flag.title}
          options={flag.options as ComboboxData}
          value={flag.value as any}
          onChange={flag.set}
        />
      );
    }

    // Compound Flag
    if ('subFlags' in flag) {
      return (
        <FeatureFlagCard
          description={flag.description}
          enabled={flag.isEnabled}
          key={flag.title}
          label={flag.title}
          onToggle={flag.toggle}
        >
          {flag.isEnabled && flag.subFlags.map((subFlag) => mapCard(subFlag))}
        </FeatureFlagCard>
      );
    }
    return (
      <FeatureFlagCard
        description={flag.description}
        enabled={flag.isEnabled}
        key={flag.title}
        label={flag.title}
        onToggle={flag.toggle}
      />
    );
  };

  return (
    <>
      <ActionIcon
        c='brand'
        m='auto'
        opacity='.4'
        radius='xl'
        size='lg'
        variant='subtle'
        onClick={handlers.open}
      >
        <IconFlask2Filled />
      </ActionIcon>

      <Drawer
        bg='gray.0'
        opened={open}
        position='left'
        size='50vw'
        title='Galileo Dev Tools'
        zIndex={Z_INDEX.DRAWERS}
        onClose={handlers.close}
      >
        <Tabs defaultValue='dev' orientation='vertical' variant='outline'>
          <Tabs.List>
            <Tabs.Tab leftSection={<IconAB2 />} value='dev'>
              <Text fw={500}>Feature Flags</Text>
            </Tabs.Tab>
            <Tabs.Tab leftSection={<IconLockAccess />} value='permissions'>
              <Text fw={500}>Permissions</Text>
            </Tabs.Tab>
            <Tabs.Tab leftSection={<IconFlask2Filled />} value='env'>
              <Text fw={500}>Dev Tools</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Container>
            <Tabs.Panel value='dev'>
              <PanelHeader
                subTitle='UI Feature toggles for Galileo users to test in development work'
                title='Feature Flags'
              />
              <Tabs defaultValue='dev' variant='outline'>
                <Tabs.List>
                  <Tabs.Tab value='dev'>A/B Flags</Tabs.Tab>
                  <Tabs.Tab value='external'>External Flags</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel my='sm' value='dev'>
                  {ABFlags.map((flag) => mapCard(flag))}
                </Tabs.Panel>

                <Tabs.Panel my='sm' value='external'>
                  {externalFeatureFlags.map((flag) => mapCard(flag))}
                </Tabs.Panel>
              </Tabs>
            </Tabs.Panel>

            <Tabs.Panel value='internal'>
              <PanelHeader
                subTitle='UI Feature toggles for Galileo users in dev and sandbox'
                title='Feature Flags'
              />
            </Tabs.Panel>

            <Tabs.Panel value='env'>
              <PanelHeader
                subTitle='Dev Tools to simulate certain environments and conditions'
                title='Dev Tools'
              />
              {devTools.map((flag) => mapCard(flag))}
            </Tabs.Panel>

            <Tabs.Panel value='permissions'>
              <PanelHeader
                subTitle='RBAC Toggles for testing permissioned features'
                title='Permissions'
              />

              <Tabs
                color='gray.1'
                defaultValue='Generic Permissions'
                mt='lg'
                orientation='vertical'
                variant='pills'
              >
                <Tabs.List>
                  {permissionFlags.map((flag) => (
                    <Tabs.Tab c='gray.7' key={flag.title} value={flag.title}>
                      {flag.title}
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
                {permissionFlags.map((flag) => (
                  <Tabs.Panel key={flag.title} value={flag.title} w='100%'>
                    <Box mx='xl' w={300}>
                      {mapCard(flag)}
                    </Box>
                  </Tabs.Panel>
                ))}
              </Tabs>
            </Tabs.Panel>
          </Container>
        </Tabs>
      </Drawer>
    </>
  );
};

const PanelHeader = ({
  title,
  subTitle
}: {
  title: string;
  subTitle: string;
}) => (
  <>
    <Text fw={600} size='md'>
      {title}
    </Text>
    <Text c='dimmed' size='sm'>
      {subTitle}
    </Text>
  </>
);

const FeatureFlagCard = ({
  children,
  label,
  description,
  enabled,
  options,
  value,
  onChange,
  onToggle
}: {
  children?: React.ReactNode;
  label: string;
  description: string;
  enabled?: boolean;
  value?: string;
  options?: ComboboxData;
  onChange?: (value: string) => void;
  onToggle?: (bool: boolean) => void;
}) => {
  return (
    <Card withBorder display='block' mb='sm' shadow='md'>
      <Group>
        <Text fw={600} size='sm'>
          {label}
        </Text>
        {onChange && (
          <Select
            comboboxProps={{
              zIndex: Z_INDEX.TOOLTIPS
            }}
            data={options}
            style={{ zIndex: Z_INDEX.TOOLTIPS }}
            value={value}
            onChange={(value) => onChange(value as string)}
          />
        )}
        {onToggle && (
          <Switch
            checked={enabled}
            onChange={(e) => onToggle(e.currentTarget.checked)}
          />
        )}
      </Group>
      <Text c='dimmed' size='xs'>
        {description}
      </Text>
      {children && (
        <ScrollAreaAutosize mah={400} py='sm'>
          {children}
        </ScrollAreaAutosize>
      )}
    </Card>
  );
};
