import { Select } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { useMemo, useState } from 'react';

import classes from '../project-menu-filters.module.css';

import SkeletonLoader from '@/core/components/atoms/skeleton-loader/skeleton-loader';
import { useUsers } from '@/core/hooks/query-hooks/use-users/use-users';
import {
  IProjectCreatorFilter,
  IProjectFilter
} from '@/core/types/project-filters.types';

export const CreatorFilter = ({
  value,
  onChange
}: {
  value: string;
  onChange: (filters: IProjectFilter[]) => void;
}) => {
  // Local State
  const [filterValue, setFilterValue] = useState(value);

  // Query Hooks
  const { data: users, isLoading } = useUsers();

  // Computed
  const usersData = useMemo(() => {
    return users?.map((user) => ({
      label: user?.email as string,
      value: user.id
    }));
  }, [users]);

  // Handlers
  const updateCreatorFilter = useDebouncedCallback((value: string) => {
    const filter: IProjectCreatorFilter = {
      name: 'creator',
      value
    };

    onChange([filter]);
  }, 500);

  const handleChange = (value: string) => {
    setFilterValue(value);
    updateCreatorFilter(value);
  };

  // Render
  if (isLoading) {
    return <SkeletonLoader height={30} length={1} mt='xs' width={300} />;
  }

  return (
    <Select
      searchable
      classNames={{ input: classes['select-input'] }}
      comboboxProps={{ zIndex: 10000, withinPortal: false }}
      data={usersData || []}
      mt={6}
      placeholder='Select user'
      value={filterValue}
      onChange={(value) => handleChange(value as string)}
    />
  );
};
