import {
  ActionIcon,
  Box,
  Center,
  Collapse,
  Group,
  Text,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import {
  IconBarbell,
  IconBookmark,
  IconBookmarkFilled,
  IconChartLine,
  IconExternalLink,
  IconFolder,
  IconFolderMinus,
  IconPlus,
  IconTrash
} from '@tabler/icons-react';
import Link from 'next/link';
import { MouseEvent } from 'react';

import classes from './project-menu-rows.module.css';

import ProjectMenuButton from '@/core/components/atoms/project-menu-button/project-menu-button';
import { Paths } from '@/core/constants/routes.constants';
import {
  TASK_TYPE,
  TaskType
} from '@/core/constants/tasks-and-frameworks.constants';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useProjectPermissions } from '@/core/hooks/use-permissions/use-project-permissions';
import { useProjectDeleteConfirmModal } from '@/core/modals/project-delete-confirm-modal/project-delete-confirm-modal';
import { Project } from '@/core/types/projects.types';
import { ProjectPermissionBlock } from '@/core/types/user-permissions.types';

const InsightsMenuRow = ({
  project,
  expandedRows,
  expandRow,
  handleBookmarkProject,
  hovered
}: {
  project: Project;
  expandedRows: string[];
  expandRow: (id: string) => void;
  handleBookmarkProject: (e: MouseEvent<HTMLButtonElement>) => void;
  hovered: boolean;
}) => {
  const { name, id: projectId, runs, bookmark, permissions } = project;

  // Feature Flags
  const { csvTraining } = useFeatureFlags();

  // Param Store
  const { runId } = usePathParameters();

  // Utility Hooks
  const showProjectDeleteConfirmModal = useProjectDeleteConfirmModal();

  // Permissions
  const { getProjectPermissions } = useProjectPermissions();
  const {
    delete: deleteProject,
    create_run,
    delete_run
  } = getProjectPermissions((permissions as ProjectPermissionBlock[]) || []);

  // Computed
  const task_type = runs?.[0]?.task_type?.toString() as TaskType;
  const isMCTCCompatible = task_type === TASK_TYPE.MCTC || runs.length === 0;
  const canAddRun = csvTraining.isEnabled && isMCTCCompatible;

  const showAddRun = canAddRun && create_run.allowed;
  const showProjectDelete = deleteProject.allowed;

  const isExpanded = Boolean(expandedRows.includes(projectId));
  const runsAmount = runs?.length;

  const folderIcon = isExpanded ? (
    <IconFolderMinus size={16} />
  ) : (
    <IconFolder size={16} />
  );

  // Render
  return (
    <Box data-testid={`project-menu-project-${name}`} key={projectId} px={4}>
      <UnstyledButton
        aria-label={`Open ${name}`}
        className='w-100'
        component='div'
        data-testid='project-menu-project'
        p='xs'
        role='button'
        onClick={() => expandRow(projectId)}
      >
        <Box className='flex justify-space-between' h={24}>
          <Box className='align-center'>
            {folderIcon}
            <Text truncate c='#383645' fw={500} maw={300} ml='xs' size='sm'>
              {name}
            </Text>
          </Box>
          <Box className='flex'>
            {hovered && (
              <>
                {showAddRun && (
                  <Tooltip
                    withArrow
                    withinPortal
                    label='Add run'
                    position='bottom'
                  >
                    <Box mr={4}>
                      <Link
                        aria-label='Add run'
                        className='unstyled-anchor'
                        href={{
                          pathname: Paths.NEW_PROJECT,
                          query: {
                            projectName: name,
                            projectId: projectId,
                            isInference: false,
                            taskType: TASK_TYPE.MCTC
                          }
                        }}
                        id={`add-run-${projectId}`}
                      >
                        <ActionIcon
                          component='div'
                          size={24}
                          variant='transparent'
                          onClick={(e) => e.stopPropagation()}
                        >
                          <IconPlus
                            className={classes.rowIcon}
                            data-testid='add-run-link'
                            size={20}
                          />
                        </ActionIcon>
                      </Link>
                    </Box>
                  </Tooltip>
                )}
                <Tooltip
                  withArrow
                  label={
                    runs.length < 2
                      ? 'Requires two or more runs to compare'
                      : 'Compare runs'
                  }
                  position='bottom'
                >
                  <Box>
                    <Link
                      aria-label='Compare Runs'
                      className='unstyled-anchor'
                      href={{
                        pathname: Paths.COMPARE_RUNS,
                        query: {
                          projectId
                        }
                      }}
                      id={`compare-runs-button-${projectId}`}
                      style={
                        runs.length < 2 ? { pointerEvents: 'none' } : undefined
                      }
                    >
                      <ActionIcon
                        color='gray.8'
                        component='div'
                        disabled={runs.length < 2}
                        size={24}
                        variant='transparent'
                        onClick={(e) => e.stopPropagation()}
                      >
                        <IconChartLine
                          className={classes.rowIcon}
                          data-testid='compare-runs-link'
                          size={20}
                        />
                      </ActionIcon>
                    </Link>
                  </Box>
                </Tooltip>
                {!bookmark && (
                  <Tooltip
                    withArrow
                    withinPortal
                    label='Bookmark'
                    position='bottom'
                  >
                    <ActionIcon
                      aria-label='Bookmark project'
                      data-testid='bookmark-icon'
                      mr={4}
                      size={24}
                      variant='transparent'
                      onClick={(e: MouseEvent<HTMLButtonElement>) =>
                        handleBookmarkProject(e)
                      }
                    >
                      <IconBookmark className={classes.rowIcon} size={16} />
                    </ActionIcon>
                  </Tooltip>
                )}
                {showProjectDelete && (
                  <Tooltip
                    withArrow
                    withinPortal
                    label='Delete project'
                    position='bottom'
                  >
                    <ActionIcon
                      aria-label='Delete project'
                      color='gray.7'
                      data-testid='delete-icon'
                      mr={4}
                      size={24}
                      variant='transparent'
                      onClick={(e: MouseEvent<HTMLButtonElement>) =>
                        showProjectDeleteConfirmModal(e, name || '', projectId)
                      }
                    >
                      <IconTrash className={classes.rowIcon} size={16} />
                    </ActionIcon>
                  </Tooltip>
                )}
              </>
            )}
            {bookmark && (
              <Tooltip
                withArrow
                withinPortal
                label='Remove bookmark'
                position='bottom'
              >
                <ActionIcon
                  aria-label='Remove bookmark'
                  color='gray.8'
                  mr={4}
                  size={24}
                  variant='transparent'
                  onClick={(e: MouseEvent<HTMLButtonElement>) =>
                    handleBookmarkProject(e)
                  }
                >
                  <IconBookmarkFilled color='#8674FB' size={16} />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>
        </Box>
      </UnstyledButton>

      <Collapse in={isExpanded} key={projectId} transitionDuration={0}>
        <Box>
          {runsAmount ? (
            runs?.map((run) => (
              <ProjectMenuButton
                createRunPermission={create_run}
                deleteRunPermission={delete_run}
                isSelected={runId === run?.id}
                key={run?.id}
                run={run}
                url={{
                  pathname: Paths.INSIGHTS,
                  query: {
                    projectId,
                    runId: run?.id,
                    taskType: run?.task_type
                  }
                }}
              />
            ))
          ) : (
            <Box mb={8}>
              <Center>
                <Center
                  h={40}
                  style={{
                    borderRadius: 8,
                    backgroundColor: '#E9E8ED'
                  }}
                  w={40}
                >
                  <IconBarbell color='#9B98AE' size={24} />
                </Center>
              </Center>
              <Center mt={6}>
                <Text c='gray.7' fw={600} size='sm'>
                  This project is empty
                </Text>
              </Center>
              <Center mb={10}>
                <Text c='gray.6' fw={400} size='sm'>
                  Looks like you don&apos;t have any runs yet
                </Text>
              </Center>
              <Center>
                <UnstyledButton
                  component='a'
                  href='https://docs.rungalileo.io/galileo/api/improve-ml-training-with-galileo'
                  target='_blank'
                >
                  <Group gap={6}>
                    <IconExternalLink color='#4A2FF9' size={16} />
                    <Text c='#4A2FF9' fw={600} size='sm'>
                      Learn more
                    </Text>
                  </Group>
                </UnstyledButton>
              </Center>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default InsightsMenuRow;
