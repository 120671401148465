import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import api from '@/core/api';

export const MY_RUNS_KEY = '/users/{user_id}/runs';

export const useMyRuns = () => {
  // TEMP! TODO: Refactor _app.tsx into more defined resposiblities, and lift this session call up so it's only called once
  const { data: session } = useSession();
  const userId = session?.userId;
  // END TEMP

  const fetchMyRuns = async () => {
    if (!userId) return Promise.resolve([]);

    const res = await api.GET(MY_RUNS_KEY, {
      params: {
        path: {
          user_id: userId
        }
      }
    });

    return res.data;
  };

  const enabled = Boolean(userId);

  const response = useQuery([MY_RUNS_KEY], fetchMyRuns, {
    enabled,
    refetchInterval: 1000 * 60 * 5, // 5 min
    refetchIntervalInBackground: true,
    staleTime: 1000 * 60 * 5 // 5 min
  });

  return response;
};
