import { Box, Text, ThemeIcon } from '@mantine/core';
import { IconAlertTriangle, IconTrash } from '@tabler/icons-react';
import { useRouter } from 'next/router';

import { Paths } from '@/core/constants/routes.constants';
import { useProjectDelete } from '@/core/hooks/query-hooks/use-project-delete/use-project-delete';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

export const useProjectDeleteConfirmModal = () => {
  const router = useRouter();
  const { projectId } = usePathParameters();
  const { openConfirmModal } = useModals();

  const deleteProject = useProjectDelete();

  const showProjectDeleteConfirmModal = (e: any, name: string, id: string) => {
    e.stopPropagation();

    openConfirmModal({
      title: (
        <Box className='align-center'>
          <ThemeIcon color='red.5' variant='light'>
            <IconAlertTriangle size={16} />
          </ThemeIcon>

          <Text ml='xs'>Delete Project</Text>
        </Box>
      ),
      children: (
        <Text c='dimmed' component='span' mt='xs'>
          Are you sure you want to delete project{' '}
          <Text c='contrast.2' component='span' fw={600}>
            {name}
          </Text>
          ?
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red.5', leftSection: <IconTrash size={16} /> },
      cancelProps: { color: 'brand.5', variant: 'subtle' },
      onConfirm: () => {
        deleteProject.mutateAsync(id, {
          onSuccess: () => {
            if (id === projectId) {
              router.push(Paths.ROOT);
            }
          }
        });
      }
    });
  };

  return showProjectDeleteConfirmModal;
};
