import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

const GET_MODEL_BY_PROJECT = 'MODELS_BY_PROJECT';

export const useModelsByProject = (_projectId?: string, _enabled?: boolean) => {
  let { projectId } = usePathParameters();
  projectId = _projectId || projectId || '';

  // API Path
  const path = '/projects/{project_id}/models/all';
  // Dependencies
  const dependencies = [projectId, _enabled];
  const enabled = dependencies.every(Boolean); // Only enable if all dependencies are truthy

  // Query
  const queryFn = async () => {
    const res = await api.GET(path, {
      params: {
        path: {
          project_id: projectId
        }
      }
    });
    return res.data;
  };

  const queryKey = [GET_MODEL_BY_PROJECT, { projectId }];

  // Response
  return useQuery({ queryKey, queryFn, enabled, onError: () => null });
};
