import { format, isThisWeek, isToday, isValid } from 'date-fns';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';

export const parseDate = (date: string | Date): string => {
  const parsedDate = new Date(date);

  if (!isValid(parsedDate)) {
    return STRING_PLACEHOLDER;
  }

  let formattedDate;
  if (isToday(parsedDate)) {
    formattedDate = format(parsedDate, 'p'); // 'p' is a date-fns token for 'hh:mm a'
  } else if (isThisWeek(parsedDate)) {
    formattedDate = format(parsedDate, "EEEE 'at' p"); // 'EEEE' is for the full day name
  } else {
    formattedDate = format(parsedDate, "MMM d, yyyy 'at' p"); // 'MMM d, yyyy' for date
  }

  return formattedDate;
};
