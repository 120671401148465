import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import QueryKeyFactory from '../query-key-factory/query-key-factory';
import { MY_RUNS_KEY } from '../use-my-runs/use-my-runs';

/**
 * WARNING: this hook will cause issues if the current project is deleted.
 *
 * To avoid that scenario, please use `useProjectDeleteConfirmModal()`
 * which includes route change logic
 */
export const useProjectDelete = () => {
  const queryClient = useQueryClient();

  const path = '/projects/{project_id}';

  const deleteProject = async (deleteProjectId: string) => {
    const res = await api.DELETE(path, {
      params: {
        path: {
          project_id: deleteProjectId
        }
      }
    });

    if (res.response.ok) {
      // Remove deleted project from projects path cache (since we don't know the project's type)
      queryClient.setQueriesData(
        [QueryKeyFactory().projects.path],
        (cache: any) => {
          return {
            ...cache,
            pages: cache?.pages?.map((page: any) => {
              return {
                ...page,
                projects: page.projects?.filter(
                  (project: any) => project?.id !== deleteProjectId
                )
              };
            })
          };
        }
      );

      queryClient.invalidateQueries([MY_RUNS_KEY]);

      showNotification({
        title: 'Project successfully deleted!'
      });
    } else {
      defaultErrorHandler(res?.response, 'Error deleting project');
    }
  };

  const mutation = useMutation(deleteProject);

  return mutation;
};
