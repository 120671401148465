import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { IProjectTypeFilter } from '@/core/types/project-filters.types';
import { ProjectType } from '@/core/types/projects.types';

export const useProjectFilters = () => {
  // Filters
  let filters = useGlobalStore((s) => s.filterProps) || [];
  const sort = useGlobalStore((s) => s.sortProps);

  const getFilters = ({ projectType }: { projectType?: ProjectType }) => {
    // Handle project type here rather than all over the place
    if (projectType != null) {
      const projectTypeFilter: IProjectTypeFilter = {
        name: 'type',
        value: projectType,
        operator: 'eq'
      };

      filters = [...filters, projectTypeFilter];
    }

    return {
      filters,
      sort
    };
  };

  return {
    getFilters
  };
};
