import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { PROJECT_BOOKMARK_PATH } from '@/core/constants/api.constants';
import { Project, ProjectType } from '@/core/types/projects.types';

import QueryKeyFactory from '../query-key-factory/query-key-factory';
import { useProjectFilters } from '../../use-project-filters/use-project-filters';

export const useProjectBookmark = () => {
  // Query Client
  const queryClient = useQueryClient();

  // Filters
  const { getFilters } = useProjectFilters();

  const handleProjectBookmark = async ({
    projectId,
    projectType,
    isBookmarked
  }: {
    projectId: string;
    isBookmarked: boolean;
    projectType: ProjectType;
  }) => {
    let res;
    let shouldDeleteCache = false;

    if (isBookmarked) {
      shouldDeleteCache = true;

      res = await api.DELETE(PROJECT_BOOKMARK_PATH, {
        params: {
          path: {
            project_id: projectId
          }
        }
      });
    } else {
      res = await api.PUT(PROJECT_BOOKMARK_PATH, {
        params: {
          path: {
            project_id: projectId
          }
        },
        // @ts-expect-error Our fetcher needs PUT requests to have a body
        body: {}
      });
    }

    if (res?.response?.ok) {
      const { sort, filters } = getFilters({ projectType });

      const bookmarkedQueryKey = QueryKeyFactory().bookmarkedProjects.queryKey({
        type: projectType,
        sort,
        filters: [
          {
            name: 'bookmark',
            value: true
          },
          {
            name: 'type',
            value: projectType,
            operator: 'eq'
          }
        ]
      });

      const projectsQueryKey = QueryKeyFactory().projects.queryKey({
        type: projectType,
        sort,
        filters
      });

      let updatedProject: Project;

      // Update the whole projects
      queryClient.setQueryData(projectsQueryKey, (cache: any) => ({
        ...cache,
        pages: cache?.pages?.map((page: any) => ({
          ...page,
          projects: page.projects?.map((project: any) => {
            if (project.id === projectId) {
              updatedProject = {
                ...project,
                bookmark: !project.bookmark
              };
              return updatedProject;
            }
            return project;
          })
        }))
      }));

      // Update the bookmarked projects cache to filter or update the bookmarked project
      queryClient.setQueryData(bookmarkedQueryKey, (cache: any) => ({
        ...cache,
        pages: cache?.pages?.map((page: any) => ({
          ...page,
          projects: shouldDeleteCache
            ? page.projects?.filter((project: any) => project.id !== projectId)
            : [
                updatedProject,
                ...page.projects.filter(
                  (project: any) => project.id !== projectId
                )
              ]
        }))
      }));
    }

    return res.data;
  };

  const bookmarkProject = useMutation(handleProjectBookmark);

  return bookmarkProject;
};
