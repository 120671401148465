import {
  ActionIcon,
  ActionIconProps,
  FloatingPosition,
  Tooltip
} from '@mantine/core';
import { forwardRef } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

interface NavActionIconProps extends ActionIconProps {
  onClick?: () => void;
  icon: React.ReactNode;
  position?: FloatingPosition;
  label: React.ReactNode;
  tooltipDisabled?: boolean;
  'data-testid'?: string;
  hoverColor?: string;
}

/**
 * NavActionIcon
 *
 *
 *
 * @returns {React.Component} NavActionIcon
 */

const NavActionIcon = forwardRef(
  (
    {
      onClick,
      position = 'right',
      icon,
      label,
      color = '#F4F4F6',
      hoverColor,
      variant,
      size = 'xl',
      tooltipDisabled,
      disabled,
      'data-testid': dataTestId
    }: NavActionIconProps,
    ref
  ) => {
    return (
      <Tooltip
        withArrow
        withinPortal
        disabled={tooltipDisabled}
        label={label}
        position={position}
        zIndex={Z_INDEX.TOOLTIPS}
      >
        <ActionIcon
          aria-label={label}
          color={color}
          data-testid={dataTestId}
          disabled={disabled}
          radius='md'
          // @ts-expect-error FIXME:
          ref={ref}
          size={size}
          style={{
            borderColor: 'var(--mantine-color-gray-2)',
            ...(hoverColor && {
              '&:hover': {
                backgroundColor: hoverColor
              }
            })
          }}
          variant={variant}
          onClick={onClick}
        >
          {icon}
        </ActionIcon>
      </Tooltip>
    );
  }
);

export default NavActionIcon;
