import { Box, Center, Group, Text, UnstyledButton } from '@mantine/core';
import { IconExternalLink, IconPlus } from '@tabler/icons-react';

import { Paths } from '@/core/constants/routes.constants';
import useProjectType from '@/core/hooks/use-project-type/use-project-type';
import { useGlobalStore } from '@/core/stores/global-store/global.store';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';
import { Permission } from '@/core/types/user-permissions.types';

import { LinkButton } from '../link-button/link-button';
import { LinkSpan } from '../link-span/link-span';

const NoProjectsByType = ({
  permissions,
  type,
  large
}: {
  permissions?: Permission;
  type: ProjectType;
  large?: boolean;
}) => {
  const { setIsProjectMenuOpen } = useGlobalStore((state) => state.actions);
  const { label, Icon, shortLabel } = useProjectType(type);

  const isTrainInf = type === PROJECT_TYPE.TRAINING_INFERENCE;

  return (
    <Box data-testid='no-projects'>
      <Center mb={12}>
        <Center
          h={large ? 110 : 40}
          style={{
            borderRadius: 24,
            backgroundColor: '#E9E8ED'
          }}
          w={large ? 110 : 40}
        >
          <Icon color='#9B98AE' size={large ? 65 : 24} />
        </Center>
      </Center>
      {large && !isTrainInf ? (
        <Box>
          <Center>
            <Text c='gray.8' fw={700} size='lg' ta='center' w={190}>
              Create an {shortLabel} Project to track here
            </Text>
          </Center>
          <Center>
            <LinkButton
              buttonProps={{
                color: 'brand.5',
                leftSection: <IconPlus size={14} />
              }}
              href={Paths.CREATE_NEW_PROJECT}
              permissions={permissions}
              onClick={() => setIsProjectMenuOpen(false)}
            >
              New Project
            </LinkButton>
          </Center>
        </Box>
      ) : (
        <>
          <Center mt={6}>
            <Text c='gray.7' fw={600} size='sm'>
              {`No recent ${label.toLowerCase()} projects`}
            </Text>
          </Center>

          <Center mt={8}>
            {type === PROJECT_TYPE.TRAINING_INFERENCE ? (
              <UnstyledButton
                component='a'
                href='https://docs.rungalileo.io/galileo/llm-studio/llm-debugger/getting-started'
                target='_blank'
              >
                <Group gap={6}>
                  <IconExternalLink color='#4A2FF9' size={16} />
                  <Text c='#4A2FF9' fw={600} size='sm'>
                    Create a {shortLabel} Run
                  </Text>
                </Group>
              </UnstyledButton>
            ) : (
              <Text c='gray.8' size='sm'>
                Create a new one{' '}
                <LinkSpan
                  href={Paths.CREATE_NEW_PROJECT}
                  permissions={permissions}
                  onClick={() => setIsProjectMenuOpen(false)}
                >
                  here
                </LinkSpan>
              </Text>
            )}
          </Center>
        </>
      )}
    </Box>
  );
};

export default NoProjectsByType;
