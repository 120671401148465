import { Text } from '@mantine/core';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { useGetUIVersion } from '@/core/hooks/query-hooks/use-get-ui-version/use-get-ui-version';

const UIVersion = () => {
  const { data: version } = useGetUIVersion();

  return (
    <Text c='gray.6' data-testid='ui-version' size='sm'>
      {`UI version ${version != null ? 'v' + version : STRING_PLACEHOLDER}`}
    </Text>
  );
};

export default UIVersion;
