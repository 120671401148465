import { ActionIcon, ActionIconProps, OverlayProps } from '@mantine/core';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, useCallback } from 'react';

import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { usePrefetch } from '@/core/hooks/use-prefetch/use-prefetch';
import { Permission } from '@/core/types/user-permissions.types';

import { PermissionOverlay } from '../permission-overlay/permission-overlay';

interface LinkActionIconProps extends LinkProps {
  ariaLabel: string;
  actionIconProps?: Omit<ActionIconProps, 'onClick'>;
  overlayProps?: OverlayProps;
  permissions?: Permission;
  onClick?: () => void;
}
export function LinkActionIcon({
  ariaLabel,
  children,
  permissions,
  actionIconProps,
  overlayProps,
  onClick,
  ...props
}: PropsWithChildren<LinkActionIconProps>) {
  // Feature Flags
  const { RBAC_M2 } = useFeatureFlags();

  // Router
  const router = useRouter();

  // Computed
  const isDisabled = RBAC_M2.isEnabled && permissions && !permissions.allowed;

  // Prefetch Hook
  usePrefetch({
    isDisabled: Boolean(isDisabled),
    href: props.href
  });

  // Handlers
  const navigate = useCallback(async () => {
    onClick?.();

    await router.push(props.href, props?.as, {
      shallow: props?.shallow,
      scroll: props?.scroll
    });
  }, []);

  // Render
  if (isDisabled) {
    return (
      <PermissionOverlay
        allowed={permissions.allowed}
        message={permissions.message}
        overlayProps={overlayProps}
      >
        <ActionIcon {...actionIconProps}>{children}</ActionIcon>
      </PermissionOverlay>
    );
  }

  return (
    <ActionIcon
      aria-label={ariaLabel}
      role='link'
      {...actionIconProps}
      onClick={navigate}
    >
      {children}
    </ActionIcon>
  );
}
