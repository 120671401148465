import { Group, Select, Text } from '@mantine/core';
import { DatePickerInput, DatePickerValue } from '@mantine/dates';
import { useDebouncedCallback } from '@mantine/hooks';
import { useEffect, useState } from 'react';

import classes from '../project-menu-filters.module.css';

import { IProjectFilter } from '@/core/types/project-filters.types';

type DateOperator = 'before' | 'after' | 'between';

const operationsData = [
  { label: 'is before', value: 'before' },
  { label: 'is after', value: 'after' },
  { label: 'is between', value: 'between' }
];

export const DateFilter = ({
  value,
  onChange,
  type
}: {
  value: string[] | null[];
  type: 'created_at' | 'updated_at';
  onChange: (filters: IProjectFilter[]) => void;
}) => {
  const parsedFromDate =
    value[0] != null && !isNaN(Date.parse(value[0]))
      ? new Date(value[0])
      : null;
  const parsedToDate =
    value[1] != null && !isNaN(Date.parse(value[1]))
      ? new Date(value[1])
      : null;

  // Local State
  const [fromDate, setFromDate] = useState<DatePickerValue>(parsedFromDate);
  const [toDate, setToDate] = useState<DatePickerValue>(parsedToDate);
  const [operation, setOperation] = useState<DateOperator>('before');

  // Computed
  const isBetween = operation === 'between';

  // Debounced Callback
  const setDateFilter = useDebouncedCallback(
    (value: [string, string] | [string], operator: DateOperator) => {
      if (operator === 'before') {
        const beforeFilter: IProjectFilter = {
          name: type,
          value: value[0],
          operator: 'lte'
        };

        return onChange([beforeFilter]);
      }

      if (operator === 'after') {
        const afterFilter: IProjectFilter = {
          name: type,
          value: value[0],
          operator: 'gte'
        };

        onChange([afterFilter]);
      }

      if (operator === 'between' && value.length === 2) {
        const betweenFilters: IProjectFilter[] = [
          {
            name: type,
            value: value[0],
            operator: 'gte'
          },
          {
            name: type,
            value: value[1],
            operator: 'lte'
          }
        ];

        onChange(betweenFilters);
      }
    },
    250
  );

  // Handlers
  const handleOperationChange = (value: string | null) => {
    if (!value) {
      return;
    }

    setOperation(value as DateOperator);
    setFromDate(null);
    setToDate(null);
  };

  useEffect(() => {
    if (isBetween && fromDate && toDate) {
      setDateFilter([fromDate.toISOString(), toDate.toISOString()], operation);
    }

    if (!isBetween && fromDate) {
      setDateFilter([fromDate.toISOString()], operation);
    }
  }, [fromDate, toDate]);

  // Render
  return (
    <>
      <Select
        classNames={{
          input: classes['select-input'],
          label: classes['select-label']
        }}
        comboboxProps={{ withinPortal: false }}
        data={operationsData}
        label='Operation'
        mt={6}
        placeholder='Select operation'
        value={operation}
        onChange={handleOperationChange}
      />
      <Group gap={10} justify='space-between' mt={6}>
        <DatePickerInput
          classNames={{
            input: classes['select-input'],
            label: classes['select-label']
          }}
          data-testid='date-filter'
          label={`${isBetween ? 'Start' : ''} Date`}
          mt={6}
          placeholder='Select date'
          popoverProps={{ withinPortal: false }}
          value={fromDate}
          w={isBetween ? 125 : '100%'}
          onChange={setFromDate}
        />
        {operation === 'between' && (
          <>
            <Text c='gray.7' mb={-30} size='sm'>
              and
            </Text>
            <DatePickerInput
              classNames={{
                input: classes['select-input'],
                label: classes['select-label']
              }}
              data-testid='date-filter'
              label='End Date'
              mt={6}
              placeholder='Select date'
              popoverProps={{ withinPortal: false }}
              value={toDate}
              w={125}
              onChange={setToDate}
            />
          </>
        )}
      </Group>
    </>
  );
};
