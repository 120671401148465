import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import {
  TASK_TYPE_LABEL_MAP,
  TaskType
} from '@/core/constants/tasks-and-frameworks.constants';

export const toHumanReadableTaskType = (taskType: TaskType) => {
  return (
    TASK_TYPE_LABEL_MAP?.[taskType as keyof typeof TASK_TYPE_LABEL_MAP] ||
    STRING_PLACEHOLDER
  );
};
