import { useInfiniteQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { IProjectFilter } from '@/core/types/project-filters.types';
import { PROJECT_TYPE, ProjectType } from '@/core/types/projects.types';

import QueryKeyFactory from '../query-key-factory/query-key-factory';
import { useProjectFilters } from '../../use-project-filters/use-project-filters';

export const useProjects = (type: ProjectType) => {
  // Feature Flags
  const { prompt, observe } = useFeatureFlags();
  let enabled = Boolean(type);

  if (type === PROJECT_TYPE.OBSERVE && !prompt.isEnabled) {
    enabled = false;
  }

  if (type === PROJECT_TYPE.PROMPT_EVALUATION && !observe.isEnabled) {
    enabled = false;
  }

  // Filters
  const { getFilters } = useProjectFilters();
  const { filters, sort } = getFilters({ projectType: type });

  // Query Key
  const queryKey = QueryKeyFactory().projects.queryKey({ type, filters, sort });

  const queryFn = async ({ pageParam = 0, limit = 25 }) => {
    const res = await api.POST(QueryKeyFactory().projects.path, {
      params: {
        query: {
          starting_token: pageParam,
          limit,
          actions: ['delete', 'create_run', 'delete_run']
        }
      },
      body: {
        filters,
        sort
      }
    });

    return res?.data;
  };

  // Response
  const response = useInfiniteQuery({
    queryKey,
    queryFn,
    enabled,
    getNextPageParam: (lastPage) => lastPage?.next_starting_token
  });

  const projects =
    response.data?.pages?.flatMap((page) => page?.projects) || [];

  return {
    ...response,
    projects
  };
};

export const useGetBookmarkedProjects = (type: ProjectType) => {
  // Feature Flags
  const { prompt, observe } = useFeatureFlags();
  let enabled = Boolean(type);

  if (type === PROJECT_TYPE.OBSERVE && !prompt.isEnabled) {
    enabled = false;
  }

  if (type === PROJECT_TYPE.PROMPT_EVALUATION && !observe.isEnabled) {
    enabled = false;
  }

  // Filters
  const { getFilters } = useProjectFilters();
  const { sort } = getFilters({ projectType: type });

  const bookmarkedProjectsFilters: IProjectFilter[] = [
    {
      name: 'bookmark',
      value: true
    },
    {
      name: 'type',
      value: type,
      operator: 'eq'
    }
  ];
  // Query Key
  const queryKey = QueryKeyFactory().bookmarkedProjects.queryKey({
    type,
    sort,
    filters: bookmarkedProjectsFilters
  });

  const queryFn = async ({ pageParam = 0, limit = 25 }) => {
    const res = await api.POST(QueryKeyFactory().bookmarkedProjects.path, {
      params: {
        query: {
          starting_token: pageParam,
          limit,
          actions: ['delete', 'create_run', 'delete_run']
        }
      },
      body: {
        filters: bookmarkedProjectsFilters,
        sort
      }
    });

    return res?.data;
  };

  // Response
  const response = useInfiniteQuery({
    queryKey,
    queryFn,
    enabled,
    getNextPageParam: (lastPage) => lastPage?.next_starting_token
  });

  const projects = response.data?.pages?.flatMap((page) => page?.projects);

  return {
    ...response,
    projects
  };
};
